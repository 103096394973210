// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    background-color: var(--color-primary);
    padding-left: 32px;
    padding-top: 24px;
    padding-right: 32px;
    padding-bottom: 8px;
    justify-content: space-between;
    width: 100%;
    font-family: Helvetica, sans-serif;
    position: fixed;

}

.right-hand-button {
    margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/header/Header.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,kCAAkC;IAClC,eAAe;;AAEnB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".header {\n    background-color: var(--color-primary);\n    padding-left: 32px;\n    padding-top: 24px;\n    padding-right: 32px;\n    padding-bottom: 8px;\n    justify-content: space-between;\n    width: 100%;\n    font-family: Helvetica, sans-serif;\n    position: fixed;\n\n}\n\n.right-hand-button {\n    margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
