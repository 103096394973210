import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

export default function api() {
    // CHANGEME your heroku instance here....
    // const baseUrl = 'https://cancelist-api.onrender.com/'
    const baseUrl = 'http://localhost:3002'

    return {
        submitLogin: async (email, password) => {
            return axios.post(`${baseUrl}/auth/login`, {
                email: email,
                password: password
            })
        },
        signup: async (firstName, lastName, email, password) => {
            return axios.post(`${baseUrl}/register/email`, {
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password
            })
        }
    }
}
