// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row {
    display: flex;
    margin: auto;
    flex-direction: row;
    flex: none;
    justify-content: center;
  }



.headerstyle {
    color: var(--color-primary);
    font-family: Helveticas, sans-serif;
    justify-content: center;
    
}

.subheader {
    font-family: Helvetica, sans-serif;
    display: flex;
    justify-content: center;
}

.margin-top {
    margin-top: 16px;
  }
  

  
.column {
    padding:0 16px;
    margin-bottom: 32px;
}

a {
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/Welcome/Welcome.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,UAAU;IACV,uBAAuB;EACzB;;;;AAIF;IACI,2BAA2B;IAC3B,mCAAmC;IACnC,uBAAuB;;AAE3B;;AAEA;IACI,kCAAkC;IAClC,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;EAClB;;;;AAIF;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".row {\n    display: flex;\n    margin: auto;\n    flex-direction: row;\n    flex: none;\n    justify-content: center;\n  }\n\n\n\n.headerstyle {\n    color: var(--color-primary);\n    font-family: Helveticas, sans-serif;\n    justify-content: center;\n    \n}\n\n.subheader {\n    font-family: Helvetica, sans-serif;\n    display: flex;\n    justify-content: center;\n}\n\n.margin-top {\n    margin-top: 16px;\n  }\n  \n\n  \n.column {\n    padding:0 16px;\n    margin-bottom: 32px;\n}\n\na {\n  color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
