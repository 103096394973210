import React from 'react';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createRoot } from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Welcome from './Welcome/Welcome';
import Login from './login/Login';
import api from './util/api'
import Signup from './signup/Signup';

const root = createRoot(document.getElementById("root"));
root.render(
  <CookiesProvider>
    <Router>
        <Routes>
          <Route exact path="/" element={<Welcome />} />
          <Route path="/login" element={ <Login api={api} />} />
          <Route path="/signup" element={<Signup api={api} />} />
        </Routes>
    </Router>
  </CookiesProvider>
);

serviceWorker.unregister();
