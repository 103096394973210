import React from 'react';
import './Welcome.css';
import { Header } from '../header/Header';
import { Link, Navigate } from 'react-router-dom'
import { withCookies, useCookies } from 'react-cookie';
import Button from 'react-bootstrap/Button';

const Welcome = () => {

    const [cookies] = useCookies([])

    const path = cookies.token ? "/home" : "/login"
    const text = cookies.token ? "Home" : "Login"
    const link = <Link to={
        {
            pathname: path
        }
    }>
        <h5>{text}</h5>
    </Link>

    return (
        <div>
            {
                // CHANGEME auth'd login here

                <div>
                    <Header rhs={link}></Header>
                    <div className="row header-content-margin">
                        <div >
                            <h1 className="headerstyle center-text">cancel.ist</h1>
                            <div className="subheader section-margin-top">what have you cancelled?</div>
                        </div>

                        
                        <center>
                        <br />
                            {link}
                        </center>
                    </div>
                </div>
            }
        </div>
    )
}


export default withCookies(Welcome)