// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (min-width: 480px) {
  .Login {
    padding: 120px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }

  button {
    width: 320px;
    margin-top: 16px;
  }

}

@media all and (max-width: 480px) {
  .Login {
    padding: 120px 0;
  }

  .Login form {
    margin: 0 auto;
    padding: 0 16px 0 16px;
  }

  button {
    width: 100%;
    margin-top: 16px;
  }

}`, "",{"version":3,"sources":["webpack://./src/login/Login.css"],"names":[],"mappings":"AAAA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,gBAAgB;EAClB;;AAEF;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,sBAAsB;EACxB;;EAEA;IACE,WAAW;IACX,gBAAgB;EAClB;;AAEF","sourcesContent":["@media all and (min-width: 480px) {\n  .Login {\n    padding: 120px 0;\n  }\n\n  .Login form {\n    margin: 0 auto;\n    max-width: 320px;\n  }\n\n  button {\n    width: 320px;\n    margin-top: 16px;\n  }\n\n}\n\n@media all and (max-width: 480px) {\n  .Login {\n    padding: 120px 0;\n  }\n\n  .Login form {\n    margin: 0 auto;\n    padding: 0 16px 0 16px;\n  }\n\n  button {\n    width: 100%;\n    margin-top: 16px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
