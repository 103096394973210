import React, { useEffect } from 'react'
import './Signup.css'
import { useInputChange } from '../util/hooks'
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap"
import { Header } from '../header/Header'
import { Navigate } from 'react-router-dom'
import { useState } from 'react'
import { withCookies, useCookies } from 'react-cookie'

const SignupForm = ({ api }) => {
    const [values, handleInputChange] = useInputChange({ email: "", password: "" })
    const [submit, setSubmit] = useState(null)
    const [signupResponse, setSignupResponse] = useState({ signup: false, message: "" })
    const [cookies, setCookie] = useCookies([])

    useEffect(() => {
        (async () => {
            if (submit) {
                try {
                    const resp = await api().signup(
                        values.firstName, values.lastName, values.email, values.password
                    )
                    if (resp.status === 200) {
                        setCookie('token', resp.data.authToken)
                        setSignupResponse({ signup: true, accessToken: resp.data.authToken })
                    } else {
                        setSignupResponse({ signup: false, message: "There was an error signing up" })
                        setSubmit(false)
                    }
                } catch (e) {
                    console.log(e)
                    setSignupResponse({ login: false, message: "There was an error signing in" })
                    setSubmit(false)
                }
            }
        })()
    }, [submit])

    const canSubmit = () => values.email && values.password && values.firstName && values.lastName

    console.log(values)
    return (
        <div>
            {signupResponse.signup ? <Navigate to="/home" /> :
                <div className="Signup">
                    <form >
                        <FormGroup controlId="firstName">
                            <FormLabel>First name</FormLabel>
                            <FormControl
                                autoFocus
                                name="firstName"
                                type="text"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup controlId="lastName">
                            <FormLabel>Last name</FormLabel>
                            <FormControl
                                name="lastName"
                                type="text"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup controlId="email">
                            <FormLabel>Email</FormLabel>
                            <FormControl
                                name="email"
                                type="email"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup controlId="password">
                            <FormLabel>Password</FormLabel>
                            <FormControl
                                onChange={handleInputChange}
                                name="password"
                                type="password"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button block disabled={!canSubmit()} onClick={() => setSubmit(true)}  >
                                Sign up
                        </Button>
                        </FormGroup>
                        {signupResponse.message ? <FormGroup> {signupResponse.message} </FormGroup> : null}
                        <FormGroup></FormGroup>
                    </form>
                </div>}
        </div>

    )
}

const Signup = withCookies(({ api }) => (
    <div>
        <Header />
        <SignupForm api={api} />
    </div>
))

export default Signup