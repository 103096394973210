// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (min-width: 480px) {
    .Signup {
      padding: 120px 0;
    }
  
    .Signup form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
  
  @media all and (max-width: 480px) {
    .Signup {
      padding: 120px 0;
    }
  
    .Signup form {
      margin: 0 auto;
      padding: 0 16px 0 16px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/signup/Signup.css"],"names":[],"mappings":"AAAA;IACI;MACE,gBAAgB;IAClB;;IAEA;MACE,cAAc;MACd,gBAAgB;IAClB;EACF;;EAEA;IACE;MACE,gBAAgB;IAClB;;IAEA;MACE,cAAc;MACd,sBAAsB;IACxB;EACF","sourcesContent":["@media all and (min-width: 480px) {\n    .Signup {\n      padding: 120px 0;\n    }\n  \n    .Signup form {\n      margin: 0 auto;\n      max-width: 320px;\n    }\n  }\n  \n  @media all and (max-width: 480px) {\n    .Signup {\n      padding: 120px 0;\n    }\n  \n    .Signup form {\n      margin: 0 auto;\n      padding: 0 16px 0 16px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
