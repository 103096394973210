import React from 'react'
import './Header.css'
import { Link } from 'react-router-dom'

export const Header = ({ rhs }) => (
    <div className="header d-inline-flex black-link">
        <h5>
            🚫📝 cancel.ist
            <Link to={
                {
                    pathname: "/"
                }
            }>
                {/* <b>cancel.ist</b> */}
            </Link>
            
        </h5>
        {rhs}
    </div>
)